@import './Global';

.app {
  display: grid;
  grid-template-columns: 250px 250px 1fr;
  height: 100vh;
  width: 100vw;

  @media (max-width: 1300px) {
    grid-template: 1fr 1fr / 225px 1fr;
  }

  @media (max-width: 1080px) {
    grid-template: 225px 1fr / 225px 1fr;
  }

  @media (max-width: 500px) {
    grid-template: 170px 1fr 250px / 1fr;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--purple);

  @media (max-width: 1300px) {
    grid-row: 1;
  }
}

.logo {
  font-style: normal;
  font-weight: 400;
  line-height: 0.67;
  text-align: center;
  font-size: 4rem;
  margin-bottom: 0;
  color: #fff;

  @media (max-width: 1080px) {
    font-size: 3rem;
  }
}

.nav {
  background-color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media (max-width: 1080px) {
    align-items: flex-start;
    padding: 0 2rem;
  }

  @media (max-width: 500px) {
    align-items: center;
  }

  & .navLink {
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: var(--purple);
    display: block;
    text-decoration: none;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    letter-spacing: 3px;
    transition: transform 0.3s ease-out;

    @media (max-width: 500px) {
      text-align: center;
    }

    &:hover,
    &:active,
    &:focus {
      transform: translateX(5px);

      @media (max-width: 500px) {
        transform: none;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: 1080px) {
    grid-column: 2;
  }

  @media (max-width: 500px) {
    grid-column: 1;
    grid-row: 3;
  }
}

.about {
  padding: 6rem 4rem;
  background-color: var(--light-purple);
  color: var(--purple);
  display: flex;
  align-items: center;

  @media (max-width: 1300px) {
    grid-row: 1 / span 2;
  }

  @media (max-width: 1080px) {
    grid-row: 2;
    grid-column: 1 / span 2;
  }

  @media (max-width: 500px) {
    padding: 3rem 2rem;
    grid-column: 1;
  }
}

.aboutHeader {
  font-style: normal;
  font-weight: 400;
  font-size: 2.25rem;
  margin-bottom: 1.6rem;
  letter-spacing: 3px;
  line-height: 1;

  @media (max-width: 500px) {
    font-size: 1.5rem;
    margin-bottom: 1.1rem;
  }
}

.aboutText {
  font-size: 1.5rem;
  line-height: 1.75;
  max-width: 57rem;

  @media (max-width: 1300px) {
    font-size: 1.5rem;
  }

  @media (max-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}

.aboutLink {
  font-family: 'Oswald', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  margin-top: 1.6rem;
  display: inline-block;
  letter-spacing: 3px;
  text-decoration: none;
  transition: transform 0.3s ease-out;

  & + .aboutLink {
    margin-left: 20px;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}
